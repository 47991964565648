import { SectionServicesListingProps } from '@portfolio/models'
import { FC } from 'react'
import { Link, Pill, SectionWrapper } from '@portfolio/components'
import { styled } from '@portfolio/styles'

const StyledSectionWrapper = styled(SectionWrapper, {
  color: '$white',
  paddingTop: '96',

  '@lg': {
    paddingTop: '$144',
  },
})

const Content = styled('div', {
  margin: '0 auto',
  containerSpace: 'medium',
  width: '100%',
})

const ServiceGroupTitle = styled('h2', {
  projectFont: 'heading08',
  marginBottom: '$40',
})

const ServicesBlocksList = styled('ul', {
  display: 'grid',
  listStyleType: 'none',
  gap: '$40 $0',
  gridTemplateColumns: '1fr',

  '@sm': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@lg': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})

const ServicesList = styled('ul', {
  listStyleType: 'none',
  display: 'grid',
  gap: '$8',
})

const LinkWrap = styled('div', {
  marginTop: '$64',
  display: 'flex',
  justifyContent: 'center',

  '@sm': {
    justifyContent: 'flex-start',
  },
})

export const SectionServicesListing: FC<SectionServicesListingProps> = ({
  cta,
  ctaLabel,
  servicesList,
}) => {
  if (!servicesList.length) return null

  return (
    <StyledSectionWrapper type="NO_MARGIN">
      <Content>
        <ServicesBlocksList>
          {servicesList?.map((servicesListItem) =>
            servicesListItem?.services ? (
              <li key={servicesListItem?._key}>
                {servicesListItem?.title && (
                  <ServiceGroupTitle>
                    {servicesListItem?.title}
                  </ServiceGroupTitle>
                )}
                <ServicesList>
                  {servicesListItem?.services?.map((service) => (
                    <li key={service}>
                      <Pill as="h3" appearance="UI05">
                        {service}
                      </Pill>
                    </li>
                  ))}
                </ServicesList>
              </li>
            ) : null,
          )}
        </ServicesBlocksList>

        <LinkWrap>
          <Link {...cta} appearance="containedTransparent">
            {ctaLabel}
          </Link>
        </LinkWrap>
      </Content>
    </StyledSectionWrapper>
  )
}
